import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '@/views/Home.vue'
import purchaseContract from '@/views/purchaseContract/index.vue'
import deliveryOrder from '@/views/deliveryOrder/index.vue'

import login from '@/views/login/index.vue'
import whiteList from '@/router/whiteList'
import store from '@/store'

function _import(path) {
  return () => import("@/views" + path)
}
const routes = [{
    path: '',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '',
    name: 'home',
    component: Home,
    children: [{
        path: '/index',
        name: 'index',
        component: () => import( /* webpackChunkName: "purchaseContract" */ '@/views/index/index.vue')
      },
      {
        path: '/purchaseContract',
        name: 'purchaseContract',
        component: purchaseContract
      },
      {
        path: '/purchaseContract/purchaseContractAdd',
        name: 'purchaseContractAdd',
        component: () => import( /* webpackChunkName: "purchaseContractAdd" */ '@/views/purchaseContract/purchaseContractAdd/index.vue')
      },
      {
        path: '/deliveryOrder',
        name: 'deliveryOrder',
        component: deliveryOrder
      },

      {
        path: '/401',
        component: _import('/error/401/index.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        component: _import('/error/404/index.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach(async (to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else if (store.state.routerFinished) {

    let paths = store.state.paths;
    if (paths.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/401");
    }
  } else {
    await new Promise((resolve, reject) => {})
  }
})

export default router