<template>
  <div class="login_wrap">
    <!-- <div class="change_login" @click="changeLogin">
      {{isAdmin?'切换至供应商入口':'切换至管理员入口'}}
    </div> -->
    <div class="form_wrap" v-if="!isAdmin">
      <div class="form_box">
        <div style="padding: 50px 45px 40px;">
          <div class="title_box">
            <p class="title">德拉姆供应商平台</p>
            <p class="subtitle">长期主义，互相成就</p>
          </div>
          <el-form :model="params" :rules="rules" ref="formRef">
            <el-form-item label="" prop="suppName" style="margin-bottom:25px">
              <el-input v-model="params.suppName" placeholder="请输入账号">
                <template #prefix>
                  <el-icon>
                    <UserFilled />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input v-model="params.password" type="password" show-password placeholder="请输入密码">
                <template #prefix>
                  <el-icon>
                    <Lock />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="params.saveMe" label="记住密码" />
            </el-form-item>
            <el-form-item label="">
              <el-button class="login_btn" type="primary" size="large" @click="onLogin" :loading="loading">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="change_login_type" @click="changeLogin">切换至管理员入口</div>

      </div>
    </div>
    <div v-else class="form_wrap_admin">

      <div class="login_type_box">
        <div :class="['login_type', {login_type_active:loginType == 1}]" @click="changeLoginType(1)">账号密码登录</div>
        <div :class="['login_type', {login_type_active:loginType == 2}]" @click="changeLoginType(2)">飞书扫码登录</div>
      </div>
      <div class="form_box_admin" v-if="loginType == 1">
        <el-form :model="adminPrams" :rules="adminRules" ref="adminFormRef">
          <el-form-item label="" prop="account" style="margin-bottom:25px">
            <el-input v-model="adminPrams.account" placeholder="请输入账号">
              <template #prefix>
                <el-icon>
                  <UserFilled />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input v-model="adminPrams.password" type="password" show-password placeholder="请输入密码">
              <template #prefix>
                <el-icon>
                  <Lock />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="adminPrams.saveMe" label="记住密码" />
          </el-form-item>
          <el-form-item label="">
            <el-button class="login_btn" type="primary" size="large" @click="onLoginAdmin" :loading="adminLoading">登录</el-button>
          </el-form-item>
        </el-form>

      </div>
      <div v-else-if="loginType == 2" style="padding: 20px 45px;min-width: 300px;min-height: 304px;position: relative;" v-loading="qrCodeLoading">
        <div id="feishu" style="text-align: center;"></div>
        <div style="
            position: absolute;
            left: 0;
            bottom: 10px;
            text-align: center;
            width: 100%;
            font-size: 14px;
            color: #898d90;
            font-size: 14px;">请使用飞书扫描二维码登录</div>
      </div>
      <div class="change_login_type" style="margin-top: 14px;" @click="changeLogin">切换至供应商入口</div>

    </div>
    <div class="el-login-footer">
      <span>
        <a href="https://beian.miit.gov.cn/" target="_blank">版权所有 @2021-2024 德拉姆供应商平台. All Rights Reserved.
          粤ICP备2021153112号-1</a>
      </span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { nextTick, onMounted, reactive, ref } from "vue";
import { login, getUserInfo, fsLogin, adminLoginApi } from "@/api/login/login";
import { useRouter, useRoute } from "vue-router";
import { createWebSocket } from "@/utils/socket";
import getRouterList from "@/router/getRouters";

export default {
  name: "Login",
  setup() {
    const route = useRoute();
    const qrCodeLoading = ref(false);
    const isAdmin = ref(false);
    const store = useStore();
    const router = useRouter();
    const formRef = ref(null);
    const adminFormRef = ref(null);
    const loading = ref(false);
    const loginType = ref(2);
    const adminLoading = ref(false);

    const rules = {
      suppName: [
        { required: true, message: "请输入账号", trigger: ["blur", "change"] },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: ["blur", "change"] },
      ],
    };
    const adminRules = {
      account: [
        { required: true, message: "请输入账号", trigger: ["blur", "change"] },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: ["blur", "change"] },
      ],
    };

    const params = reactive({
      suppName: "",
      account: "admin",
      password: "",
      saveMe: false,
      // systemCode:"ERP" //SUPP_COOPERATE
    });
    const adminPrams = reactive({
      account: "",
      password: "",
      saveMe: false,
      // systemCode:"ERP" //SUPP_COOPERATE
    });

    const onLogin = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          login(params).then((res) => {
            if (res.resCode == 0) {
              store.commit("clearTagsRouter");
              getRouterList();
              getUserInfo().then((res) => {
                if (res.resCode == 0) {
                  store.commit("userInfo", res.data);
                }
              });
              createWebSocket();
              if (params.saveMe) {
                localStorage.setItem("saveMe", JSON.stringify(params));
              } else {
                localStorage.removeItem("saveMe");
              }
              router.replace({
                path: "/",
              });
            }
            loading.value = false;
          });
        }
      });
    };

    const changeLogin = () => {
      isAdmin.value = !isAdmin.value;
      loginType.value = 2;
      nextTick(() => {
        initFeishu();
      });
    };

    const initFeishu = () => {
      let origin =
        process.env.NODE_ENV == "development"
          ? "https://test.supp.idelamu.com"
          : location.origin;
      var url = origin + location.pathname;
      var client_id = "cli_a5ec0cbf79a9500b";
      var goto = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${client_id}&response_type=code&state=feishu&redirect_uri=${url}`;
      var QRLoginObj = QRLogin({
        id: "feishu",
        goto, //请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width: "300",
        height: "300",
      });
      qrCodeLoading.value = false;
      var handleMessage = function (event) {
        if (
          QRLoginObj.matchOrigin(event.origin) &&
          QRLoginObj.matchData(event.data)
        ) {
          var loginTmpCode = event.data.tmp_code;
          // 在授权页面地址上拼接上参数 tmp_code，并跳转
          window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    };
    const changeLoginType = (type) => {
      if (loginType.value == type) {
        return;
      }
      loginType.value = type;
      nextTick(() => {
        if (type == 2) {
          initFeishu();
        }
      });
    };
    const codeLoginFeishu = (scanCodeParams) => {
      fsLogin(scanCodeParams)
        .then((res) => {
          if (res) {
            if (res.resCode == 0) {
              store.commit("clearTagsRouter");
              getRouterList();
              getUserInfo().then((res) => {
                if (res.resCode == 0) {
                  store.commit("userInfo", res.data);
                }
              });
              router.replace({
                path: "/",
              });
            } else {
              initFeishu();
            }
          } else {
            initFeishu();
          }
        })
        .catch(() => {
          initFeishu();
        });
    };

    const onLoginAdmin = () => {
      adminFormRef.value.validate((valid) => {
        if (valid) {
          adminLoading.value = true;
          adminLoginApi(adminPrams).then((res) => {
            if (res.resCode == 0) {
              store.commit("clearTagsRouter");
              getRouterList();
              getUserInfo().then((res) => {
                if (res.resCode == 0) {
                  store.commit("userInfo", res.data);
                }
              });
              if (adminPrams.saveMe) {
                localStorage.setItem("adminSaveMe", JSON.stringify(adminPrams));
              } else {
                localStorage.removeItem("adminSaveMe");
              }
              router.replace({
                path: "/",
              });
            }
            adminLoading.value = false;
          });
        }
      });
    };

    onMounted(() => {
      qrCodeLoading.value = true;
      let saveMe = localStorage.getItem("saveMe");
      if (saveMe) {
        let info = JSON.parse(saveMe);
        params.suppName = info.suppName;
        params.password = info.password;
        params.saveMe = info.saveMe;
      }
      let adminSaveMe = localStorage.getItem("adminSaveMe");
      if (adminSaveMe) {
        let info = JSON.parse(adminSaveMe);
        adminPrams.account = info.account;
        adminPrams.password = info.password;
        adminPrams.saveMe = info.saveMe;
      }
      let state = route.query.state;
      let code = route.query.code;
      if (state == "feishu" && code) {
        isAdmin.value = true;
        codeLoginFeishu({ code,isSaveMe:true });
        return;
      }

      initFeishu();
    });
    return {
      loading,
      params,
      rules,
      formRef,
      onLogin,
      changeLogin,
      isAdmin,
      changeLoginType,
      loginType,
      qrCodeLoading,
      adminPrams,
      adminRules,
      onLoginAdmin,
      adminLoading,
      adminFormRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.login_wrap {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(20deg, #63ad5c, #39a0ff);
  // background: linear-gradient(rgba(0, 51, 153, 0.1), rgba(0, 51, 153, 0.1)),
  // url("@/assets/img/loginBg.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100% 100%;
  .change_login {
    position: fixed;
    top: 50px;
    right: 80px;
    color: #004adf;
    cursor: pointer;
    font-weight: bold;
  }
  .title_box {
    text-align: center;
    margin-bottom: 60px;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 16px;
      margin: 20px 0;
    }
  }
  .form_wrap {
    padding-top: 18vh;
    position: relative;
    z-index: 100;
    .form_box {
      margin: 0 auto;
      // padding: 50px 50px 40px;
      width: 420px;
      background-color: white;
      border-radius: 6px;
    }
    .login_btn {
      width: 100%;
      margin-top: 30px;
    }
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
    white-space: nowrap;
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .form_wrap_admin {
    position: fixed;
    top: 18vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    .login_type_box {
      display: flex;

      .login_type {
        padding: 20px 0;
        flex: 1;
        text-align: center;
        cursor: pointer;
        background-color: #f2f6fc;
        font-weight: bold;
      }
      .login_type_active {
        color: #004adf;
        background-color: #ffffff;
      }
    }
    .form_box_admin {
      padding: 50px 40px;
      width: 310px;
    }
  }
}
.login_btn {
  width: 100%;
  margin-top: 30px;
}
.change_login_type {
  text-align: center;
  color: #004adf;
  border-top: 1px solid #f2f2f2;
  padding: 13px 0;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>